import type { InputHTMLAttributes, LabelHTMLAttributes } from 'react'
import { FormField, FormItem, FormMessage } from '@/components/ui/form'
import { Label } from '@/components/ui/label'
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group'
import { cn } from '@/lib/utils'
import type { FieldValues, Path } from 'react-hook-form'
import { useFormContext } from 'react-hook-form'

import { camelCaseToSpacedTitleCase } from '@acme/shared'

interface MdRadioGroupProps {
  label?: string
  labelProps?: LabelHTMLAttributes<HTMLLabelElement>
  inputProps?: InputHTMLAttributes<HTMLInputElement>
  containerClassName?: string
  name: string
  onChange: (e: string) => void
  value: string
  options: { value: string; label: string }[]
}

const MdRadioGroup = ({
  label,
  value,
  name,
  labelProps,
  inputProps,
  containerClassName,
  onChange,
  options,
}: MdRadioGroupProps) => {
  const usedLabel = label ?? camelCaseToSpacedTitleCase(name ?? '')
  const { className: labelClassName, ...labelRest } = labelProps ?? {}
  const { className: inputClassName } = inputProps ?? {}

  return (
    <div className={cn(containerClassName, 'mt-1')}>
      <div className='grid w-full items-center gap-1.5'>
        <Label htmlFor='name' className={labelClassName} {...labelRest}>
          {usedLabel}
        </Label>
        <RadioGroup
          // onChange={(val) => console.log("asdasdas", val)}
          value={value}
          className={inputClassName}
        >
          {options.map((option) => (
            <div className='flex items-center space-x-2' key={option.value}>
              <RadioGroupItem
                value={option.value}
                id={option.value}
                onClick={() => onChange(option.value)}
              />
              <Label htmlFor='option-two'>{option.label}</Label>
            </div>
          ))}
        </RadioGroup>
      </div>
    </div>
  )
}

const ControlledMdRadioGroup = <T extends FieldValues>({
  name,
  options,
  ...rest
}: {
  name: Path<T>
} & Omit<MdRadioGroupProps, 'onChange' | 'value'>) => {
  const { control } = useFormContext()
  return (
    <FormField
      control={control}
      name={name}
      render={({ field: { value, onChange, ...fieldRest } }) => {
        return (
          <FormItem>
            <MdRadioGroup
              {...fieldRest}
              {...rest}
              options={options}
              value={value}
              onChange={onChange}
            />
            <FormMessage />
          </FormItem>
        )
      }}
    />
  )
}

export { ControlledMdRadioGroup, MdRadioGroup }
export type { MdRadioGroupProps }
