import type { InputHTMLAttributes, LabelHTMLAttributes } from 'react'
import React from 'react'
import {
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '@/components/ui/form'
import { Label } from '@/components/ui/label'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { cn } from '@/lib/utils'
import type { FieldValues, Path } from 'react-hook-form'
import { useFormContext } from 'react-hook-form'

import { camelCaseToSpacedTitleCase } from '@acme/shared'

type SelectProps = React.ComponentProps<typeof Select>
interface AdditionalProps {
  label?: string
  labelProps?: LabelHTMLAttributes<HTMLLabelElement>
  inputProps?: InputHTMLAttributes<HTMLInputElement>
  containerClassName?: string
  name: string
  options: { value: string; label: string }[]
  onChange: (val: string) => void
  required?: boolean
}

type MdSelectProps = SelectProps & AdditionalProps

const MdSelect = ({
  label,
  name,
  onChange,
  value,
  inputProps,
  labelProps,
  options,
  containerClassName,
  required,
}: MdSelectProps) => {
  const usedLabel = label ?? camelCaseToSpacedTitleCase(name ?? '')
  const { className: labelClassName, ...labelRest } = labelProps ?? {}
  const { className: inputClassName } = inputProps ?? {}

  return (
    <div className={cn(containerClassName, 'mt-1')}>
      <div className='grid w-full items-center gap-1.5'>
        <Label htmlFor='name' className={labelClassName} {...labelRest}>
          {usedLabel}
          {required ? <span className='text-error ml-1'>*</span> : null}
        </Label>
        <div className={inputClassName}>
          {/* Must have value or it won't update when the form value changes */}
          <Select onValueChange={onChange} defaultValue={value} value={value}>
            <FormControl>
              <SelectTrigger>
                <SelectValue placeholder='Select a verified email to display' />
              </SelectTrigger>
            </FormControl>
            <SelectContent>
              {options.map((option) => (
                <SelectItem value={option.value} key={option.value}>
                  {option.label}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
      </div>
    </div>
  )
}

const ControlledMdSelect = <T extends FieldValues>({
  name,
  options,
  ...rest
}: {
  name: Path<T>
} & Omit<MdSelectProps, 'onChange' | 'value'>) => {
  const { control } = useFormContext()
  return (
    <FormField
      control={control}
      name={name}
      render={({ field: { value, onChange, ...fieldRest } }) => {
        return (
          <FormItem>
            <MdSelect
              {...rest}
              {...fieldRest}
              options={options}
              value={value}
              onChange={onChange}
            />
            <FormMessage />
          </FormItem>
        )
      }}
    />
  )
}

export { ControlledMdSelect, MdSelect }
export type { MdSelectProps }
