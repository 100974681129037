import { Button } from '@/components/ui/button'
import { Form } from '@/components/ui/form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { z } from 'zod'

import { ControlledMdCalendar } from '~/components/MdCalendar'
import { ControlledMdCheckbox } from '~/components/MdCheckbox'
import { ControlledMdComboBox } from '~/components/MdComboBox'
import { ControlledMdRadioGroup } from '~/components/MdRadioGroup'
import { ControlledMdSelect } from '~/components/MdSelect'
import { ControlledMdSlider } from '~/components/MdSlider'
import { ControlledMdSwitch } from '~/components/MdSwitch'
import { ControlledMdTextField } from '~/components/MdTextField'

const FormSchema = z.object({
  email: z.string().email(),
  password: z.string().min(5),
  firstName: z.string().min(5),
  lastName: z.string().min(5),
  country: z.string().min(5),
  gender: z.string(),
  age: z.number().array(),
  notifications: z.boolean(),
  emailNotif: z.boolean(),
  city: z.string(),
  dateOfBirth: z.date(),
})

const Login = () => {
  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      email: '',
      password: '',
      firstName: '',
      lastName: '',
      country: '',
      gender: '',
      age: [0],
      notifications: false,
      city: '',
      dateOfBirth: undefined,
      emailNotif: false,
    },
  })

  return (
    <div className='mt-50 m-auto flex max-w-[600px] flex-col gap-3 pt-[50px]'>
      <Form {...form}>
        <ControlledMdTextField name='firstName' />
        <ControlledMdCalendar name='dateOfBirth' mode='single' />
        <ControlledMdComboBox
          name='country'
          options={[
            {
              value: 'PH',
              label: 'PH',
            },
            {
              value: 'US',
              label: 'US',
            },
            {
              value: 'JPN',
              label: 'JPN',
            },
          ]}
        />
        <ControlledMdRadioGroup
          name='gender'
          options={[
            {
              value: 'Male',
              label: 'Male',
            },
            {
              value: 'Female',
              label: 'Female',
            },
          ]}
          inputProps={{
            className: 'flex flex-row',
          }}
        />
        <ControlledMdSelect
          name='city'
          options={[
            {
              value: 'QC',
              label: 'Quezon City',
            },
            {
              value: 'M',
              label: 'Manila',
            },
          ]}
        />
        <ControlledMdSlider name='age' step={1} max={50} />
        <ControlledMdCheckbox name='emailNotif' label='Email Notifications' />
        <ControlledMdSwitch name='notifications' />
        <Button onClick={form.handleSubmit(() => alert())} className='mt-5'>
          Submit
        </Button>
      </Form>
    </div>
  )
}

export default Login
