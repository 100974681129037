import type { InputHTMLAttributes, LabelHTMLAttributes } from 'react'
import React from 'react'
import {
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Label } from '@/components/ui/label'
import { Switch } from '@/components/ui/switch'
import { cn } from '@/lib/utils'
import type { FieldValues, Path } from 'react-hook-form'
import { useFormContext } from 'react-hook-form'

import { camelCaseToSpacedTitleCase, classNames } from '@acme/shared'

type SwitchProps = React.ComponentProps<typeof Switch>

interface AdditionalProps {
  label?: string
  labelProps?: LabelHTMLAttributes<HTMLLabelElement>
  inputProps?: InputHTMLAttributes<HTMLInputElement>
  containerClassName?: string
  name: string
  required?: boolean
}

type MdSwitchProps = SwitchProps & AdditionalProps

const MdSwitch = ({
  label,
  name,
  labelProps,
  inputProps,
  containerClassName,
  onCheckedChange,
  checked,
  required,
  ...props
}: MdSwitchProps) => {
  const usedLabel = label ?? camelCaseToSpacedTitleCase(name ?? '')
  const { className: labelClassName, ...labelRest } = labelProps ?? {}
  const { className: inputClassName } = inputProps ?? {}

  return (
    <div className={cn(containerClassName, 'mt-1')}>
      <div className='grid w-full items-center gap-1.5'>
        <Label htmlFor='name' className={labelClassName} {...labelRest}>
          {usedLabel}
          {required ? <span className='text-error ml-1'>*</span> : null}
        </Label>
        <Switch
          {...props}
          id={name}
          className={classNames(inputClassName)}
          checked={checked}
          onCheckedChange={onCheckedChange}
        />
      </div>
    </div>
  )
}

const ControlledMdSwitch = <T extends FieldValues>({
  name,
  ...props
}: {
  name: Path<T>
} & Omit<MdSwitchProps, 'checked' | 'onCheckedChange'>) => {
  const { control } = useFormContext()
  return (
    <FormField
      name={name}
      control={control}
      render={({ field: { value, onChange, ...fieldRest } }) => {
        return (
          <FormItem>
            <FormLabel />
            <MdSwitch
              {...props}
              {...fieldRest}
              onCheckedChange={onChange}
              checked={value}
              name={name}
            />
            <FormMessage />
          </FormItem>
        )
      }}
    />
  )
}

export { ControlledMdSwitch, MdSwitch }
export type { MdSwitchProps }
