import type { InputHTMLAttributes, LabelHTMLAttributes } from 'react'
import React from 'react'
import {
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Label } from '@/components/ui/label'
import { Slider } from '@/components/ui/slider'
import { cn } from '@/lib/utils'
import type { FieldValues, Path } from 'react-hook-form'
import { useFormContext } from 'react-hook-form'

import { camelCaseToSpacedTitleCase, classNames } from '@acme/shared'

type SliderProps = React.ComponentProps<typeof Slider>
interface AdditionalProps {
  label?: string
  labelProps?: LabelHTMLAttributes<HTMLLabelElement>
  inputProps?: InputHTMLAttributes<HTMLInputElement>
  containerClassName?: string
  name: string
  value: number[]
  onValueChange: (val: number[]) => void
  required?: boolean
}

type MdSliderProps = Omit<SliderProps, 'onValuChange'> & AdditionalProps

const MdSlider = ({
  label,
  name,
  labelProps,
  inputProps,
  containerClassName,
  onValueChange,
  value,
  step = 1,
  max = 100,
  required,
  ...props
}: MdSliderProps) => {
  const usedLabel = label ?? camelCaseToSpacedTitleCase(name ?? '')
  const { className: labelClassName, ...labelRest } = labelProps ?? {}
  const { className: inputClassName } = inputProps ?? {}

  return (
    <div className={cn(containerClassName, 'mt-1')}>
      <div className='grid w-full items-center gap-1.5'>
        <Label htmlFor='name' className={labelClassName} {...labelRest}>
          {usedLabel}
          {required ? <span className='text-error ml-1'>*</span> : null}
        </Label>
        <Slider
          id={name}
          className={classNames(inputClassName)}
          onDrag={(e) => console.log(e)}
          value={value || [50]}
          {...props}
          step={step}
          max={max}
          onValueChange={(val: number[]) => onValueChange(val)}
        />
      </div>
    </div>
  )
}

const ControlledMdSlider = <T extends FieldValues>({
  name,
  ...props
}: {
  name: Path<T>
} & Omit<MdSliderProps, 'onChange' | 'value' | 'onValueChange'>) => {
  const { control } = useFormContext()
  return (
    <FormField
      control={control}
      name={name}
      render={({ field: { value, onChange, ...fieldRest } }) => {
        return (
          <FormItem>
            <FormLabel />
            <MdSlider
              {...props}
              {...fieldRest}
              value={value}
              onValueChange={onChange}
            />
            <FormMessage />
          </FormItem>
        )
      }}
    />
  )
}

export { ControlledMdSlider, MdSlider }
export type { MdSliderProps }
